import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../config";
import CustomerLogin from "./CustomerLogin";
import BackButton from "./BackButton";
import NotificationCart from "./NotificationCart";

const MenuDisplay = () => {
  const query = new URLSearchParams(useLocation().search);
  const restaurant_id = query.get("id");
  localStorage.setItem("restaurant_id", restaurant_id);

  // Existing states
  const [menu, setMenu] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("currentCart");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [restaurantDetail, setRestaurantDetail] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [categories, setCategories] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [showCartModal, setShowCartModal] = useState(false);

  // New states for login functionality
  const [isLoginVisible, setLoginVisible] = useState(false);
  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem("customerData");
    return storedData && storedData !== "undefined"
      ? JSON.parse(storedData)
      : null;
  });

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 100; // Adjust this value based on your top header height
      setIsScrolled(window.scrollY > headerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const showLoginButtonPaths = ["/menu-display", "/cart", "/customer_details"];
  // Login handlers
  const handleLoginClick = () => setLoginVisible(true);
  const handleCloseLogin = () => setLoginVisible(false);
  const handleLoginSuccess = (data) => {
    setUserData(data);
    setLoginVisible(false);
  };

  // Cart position states and handlers
  const [touching, setTouching] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [cartPosition, setCartPosition] = useState(() => {
    const saved = localStorage.getItem("cartPosition");
    return saved ? JSON.parse(saved) : { x: window.innerWidth - 100, y: 20 };
  });

  const [touchPosition, setTouchPosition] = useState({
    x: window.innerWidth - 80,
    y: window.innerHeight / 2,
  });

  // Drag handlers
  const handleDragStart = (e) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    e.dataTransfer.setData("text/plain", `${offsetX},${offsetY}`);
  };

  const handleDrag = (e) => {
    if (e.clientX && e.clientY) {
      const [offsetX, offsetY] = e.dataTransfer
        .getData("text/plain")
        .split(",");
      const x = Math.min(
        Math.max(0, e.clientX - offsetX),
        window.innerWidth - 100
      );
      const y = Math.min(
        Math.max(0, e.clientY - offsetY),
        window.innerHeight - 100
      );
      setCartPosition({ x, y });
      localStorage.setItem("cartPosition", JSON.stringify({ x, y }));
    }
  };

  // Mobile touch handlers
  const handleMobileTouchStart = (e) => {
    const touch = e.touches[0];
    setTouching(true);
    setDragOffset({
      x: touch.clientX - touchPosition.x,
      y: touch.clientY - touchPosition.y,
    });
  };

  const handleMobileTouchMove = (e) => {
    if (touching) {
      const touch = e.touches[0];
      const newX = Math.min(
        Math.max(0, touch.clientX - dragOffset.x),
        window.innerWidth - 80
      );
      const newY = Math.min(
        Math.max(0, touch.clientY - dragOffset.y),
        window.innerHeight - 80
      );
      setTouchPosition({ x: newX, y: newY });
    }
  };

  const handleMobileTouchEnd = () => setTouching(false);

  // Image and cart handlers
  const handleImageClick = (imageUrl) => setPreviewImage(imageUrl);
  const closePreview = () => setPreviewImage(null);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}/customer/display-menu/${restaurant_id}`
        );
        const allItems = response.data;
        setMenu(allItems.menu);
        setRestaurantDetail(allItems.resturant);

        const uniqueCategories = [
          ...new Set(
            allItems.menu
              .filter((item) => item.category !== "todaysSpecial")
              .map((item) => item.category)
          ),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        setError("Failed to load menu. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchMenu();
  }, [restaurant_id]);

  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);

  const showNotification = (message, status) => {
    setNotificationMessage(message);
    setNotificationStatus(status);
    setIsNotification(true);
    setTimeout(() => {
      setIsNotification(false);
    }, 9000);
  };

  useEffect(() => {
    const previousRestaurantId = localStorage.getItem("previous_restaurant_id");

    if (previousRestaurantId && previousRestaurantId !== restaurant_id) {
      setCart([]);
      localStorage.removeItem("currentCart");
      showNotification(
        "Your cart has been cleared as you switched to a different restaurant",
        "info"
      );
    }

    localStorage.setItem("previous_restaurant_id", restaurant_id);
  }, [restaurant_id]);

  // Cart management functions
  const handleAddToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((i) => i.menu_id === item.menu_id);
      return existingItem
        ? prevCart.map((i) =>
            i.menu_id === item.menu_id ? { ...i, quantity: i.quantity + 1 } : i
          )
        : [...prevCart, { ...item, quantity: 1 }];
    });
  };

  const handleUpdateCart = (menuId, quantity) => {
    quantity <= 0
      ? setCart((prevCart) =>
          prevCart.filter((item) => item.menu_id !== menuId)
        )
      : setCart((prevCart) =>
          prevCart.map((item) =>
            item.menu_id === menuId ? { ...item, quantity } : item
          )
        );
  };

  const handleCheckout = () => {
    if (cart.length === 0) {
      alert("Your cart is empty.");
      return;
    }
    navigate("/cart", { state: { cart, restaurant_id } });
  };

  const calculateTotal = () =>
    cart
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);

  if (isLoading) return <div className="text-center py-6">Loading menu...</div>;
  if (error)
    return <div className="text-red-500 text-center py-6">{error}</div>;

  const specialItems = menu.filter((item) => item.category === "TodaysSpecial");
  const regularItems = menu.filter((item) => item.category !== "TodaysSpecial");

  // Add this state for tracking scroll position
  // const [isScrolled, setIsScrolled] = useState(false);

  // Add this useEffect for scroll handling

  return (
    <div className="max-w-7xl mx-auto p-4 md:mt-5">
      <BackButton />
      <div className="max-w-7xl mx-auto p-4 mt-18 md:mt-15">
        {isNotification && (
          <NotificationCart
            message={notificationMessage}
            status={notificationStatus}
          />
        )}
        <div className="max-w-6xl mx-auto px-4 py-5 relative">
          {/* Floating Header Card */}
          <div
            className={`${
              isScrolled ? "fixed top-0" : "relative"
            } left-0 right-0 z-40 bg-white/95 backdrop-blur-sm shadow-lg transition-all duration-300`}
          >
            <div className="max-w-7xl mx-auto mb-4 p-4">
              <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="flex-1 text-center md:text-left">
                  <h1 className="text-2xl font-bold mb-1">
                    {restaurantDetail.name}
                  </h1>
                  <div className="flex flex-col md:flex-row items-center text-gray-600 text-sm">
                    <span className="mb-1 md:mb-0">
                      {restaurantDetail.address}
                    </span>
                    {restaurantDetail.phone && (
                      <>
                        <span className="hidden md:inline mx-2">•</span>
                        <span>
                          {restaurantDetail.phone.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "$1-$2-$3"
                          )}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/* Login Integration */}
                {showLoginButtonPaths.includes(location.pathname) &&
                  (userData ? (
                    <div className="relative">
                      <button
                        onClick={() => setShowDropdown(!showDropdown)}
                        className="flex items-center gap-2 bg-white px-4 py-2 rounded-md shadow-sm hover:bg-gray-50"
                      >
                        <span>{userData.customer_name}</span>
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>

                      {showDropdown && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                          <button
                            onClick={() => {
                              navigate("/customer_orders", {
                                state: { customerId: userData.customer_id },
                              });
                              setShowDropdown(false);
                            }}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Order Status
                          </button>
                          <button
                            onClick={() => {
                              localStorage.removeItem("customerData");
                              setUserData(null);
                              setShowDropdown(false);
                            }}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Logout
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <button
                      onClick={handleLoginClick}
                      className="px-4 py-2 bg-[#abbfff] text-white rounded-md hover:bg-[#4e7ab4]"
                    >
                      Login
                    </button>
                  ))}
              </div>
            </div>
          </div>

          {/* Login Modal */}
          {isLoginVisible && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
              <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                <CustomerLogin
                  onClose={handleCloseLogin}
                  onLoginSuccess={handleLoginSuccess}
                />
              </div>
            </div>
          )}

          {/* Cart Icon */}
          {cart.length > 0 && (
            <>
              {/* Mobile Cart Icon */}
              <div
                className="fixed z-50 touch-none block md:hidden"
                style={{
                  top: touchPosition.y,
                  left: touchPosition.x,
                  transform: "translate3d(0, 0, 0)",
                  touchAction: "none",
                }}
                onTouchStart={handleMobileTouchStart}
                onTouchMove={handleMobileTouchMove}
                onTouchEnd={handleMobileTouchEnd}
              >
                <div className="flex flex-col items-center">
                  <div
                    className="relative cursor-pointer bg-white p-3 rounded-full shadow-lg hover:shadow-xl transition-shadow"
                    onClick={() =>
                      !touching && setShowCartModal(!showCartModal)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 text-[#abbfff]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                    <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm">
                      {cart.reduce((total, item) => total + item.quantity, 0)}
                    </span>
                  </div>
                  <span className="text-sm font-medium text-gray-700 mt-1">
                    Your Cart
                  </span>
                </div>
              </div>

              {/* Desktop Cart Icon */}
              <div
                className="fixed z-50 touch-none hidden md:block"
                style={{
                  top: cartPosition.y,
                  left: cartPosition.x,
                  cursor: "move",
                  transform: "translate3d(0, 0, 0)",
                  touchAction: "none",
                }}
                draggable="true"
                onDragStart={handleDragStart}
                onDrag={handleDrag}
              >
                {/* Same content as mobile cart */}
                <div className="flex flex-col items-center">
                  <div
                    className="relative cursor-pointer bg-white p-3 rounded-full shadow-lg hover:shadow-xl transition-shadow"
                    onClick={() => setShowCartModal(!showCartModal)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 text-[#abbfff]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                    <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm">
                      {cart.reduce((total, item) => total + item.quantity, 0)}
                    </span>
                  </div>
                  <span className="text-sm font-medium text-gray-700 mt-1">
                    Your Cart
                  </span>
                </div>
              </div>
            </>
          )}

          {/* Cart Modal */}
          {showCartModal && (
            <div className="fixed right-4 top-16 w-80 bg-white rounded-lg shadow-xl z-50 p-4 max-h-[80vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold">Cart Items</h3>
                <button
                  onClick={() => setShowCartModal(false)}
                  className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              {cart.length === 0 ? (
                <p className="text-center text-gray-500 py-4">
                  Your cart is empty
                </p>
              ) : (
                <>
                  <div className="space-y-4">
                    {cart.map((item) => (
                      <div
                        key={item.menu_id}
                        className="flex items-center justify-between border-b pb-3"
                      >
                        <div className="flex-1">
                          <p className="font-semibold text-sm">
                            {item.item_name}
                          </p>
                          <p className="text-sm text-gray-600">
                            ${item.price} x {item.quantity}
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <button
                            className="px-2 py-1 bg-gray-100 rounded hover:bg-gray-200"
                            onClick={() =>
                              handleUpdateCart(item.menu_id, item.quantity - 1)
                            }
                          >
                            -
                          </button>
                          <span className="w-8 text-center">
                            {item.quantity}
                          </span>
                          <button
                            className="px-2 py-1 bg-gray-100 rounded hover:bg-gray-200"
                            onClick={() => handleAddToCart(item)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="border-t pt-3 mt-3">
                    <div className="flex justify-between items-center mb-4">
                      <span className="font-semibold">Subtotal:</span>
                      <span className="font-bold">${calculateTotal()}</span>
                    </div>
                    <button
                      onClick={handleCheckout}
                      className="w-full bg-[#abbfff] text-white py-2 rounded hover:bg-[#4e7ab4] transition-colors font-medium"
                    >
                      Proceed to Checkout
                    </button>
                  </div>
                </>
              )}
            </div>
          )}

          {/* Today's Special Section */}
          {specialItems.length > 0 && (
            <div className="mb-12">
              <h2 className="text-2xl font-bold text-center mb-6">
                Today's Special
              </h2>
              <div className="overflow-x-auto">
                <div className="flex space-x-6 pb-4">
                  {specialItems.map((item) => (
                    <div
                      key={item.menu_id}
                      className="bg-white rounded-lg shadow-lg overflow-hidden flex-shrink-0 w-64"
                    >
                      <img
                        src={item.image}
                        alt={item.item_name}
                        className="w-full h-48 object-cover cursor-pointer"
                        onClick={() => handleImageClick(item.image)}
                      />
                      <div className="p-4">
                        <h3 className="text-xl font-semibold mb-2">
                          {item.item_name}
                        </h3>
                        <p className="text-gray-600 mb-4 line-clamp-2">
                          {item.description}
                        </p>
                        <div className="flex justify-between items-center">
                          <span className="text-xl font-bold">
                            ${item.price}
                          </span>
                          <button
                            onClick={() => handleAddToCart(item)}
                            className="bg-[#abbfff] text-white px-4 py-2 rounded hover:bg-[#4e7ab4] transition"
                          >
                            Add to Cart
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Category Filter */}
          <div className="flex overflow-x-auto gap-4 mb-8 pb-2">
            <button
              className={`px-4 py-2 rounded ${
                selectedCategory === "all"
                  ? "bg-[#abbfff] text-white"
                  : "bg-gray-100 hover:bg-gray-200"
              }`}
              onClick={() => setSelectedCategory("all")}
            >
              All Items
            </button>

            {categories
              .filter((category) => category !== "TodaysSpecial")
              .map((category) => (
                <button
                  key={category}
                  className={`px-4 py-2 rounded-full ${
                    selectedCategory === category
                      ? "bg-[#abbfff] text-white"
                      : "bg-gray-100 hover:bg-gray-200"
                  }`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </button>
              ))}
          </div>

          {/* Regular Menu Items Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {regularItems
              .filter(
                (item) =>
                  selectedCategory === "all" ||
                  item.category === selectedCategory
              )
              .map((item) => (
                <div
                  key={item.menu_id}
                  className="bg-white rounded-lg shadow-lg p-4"
                >
                  <img
                    src={item.image}
                    alt={item.item_name}
                    className="w-full h-48 object-cover rounded cursor-pointer mb-4"
                    onClick={() => handleImageClick(item.image)}
                  />
                  <h3 className="text-xl font-semibold mb-2">
                    {item.item_name}
                  </h3>
                  <p className="text-gray-600 mb-4">{item.description}</p>
                  <div className="flex items-center justify-between">
                    <span className="text-xl font-bold">${item.price}</span>
                    <div className="flex items-center gap-3">
                      <button
                        className="px-3 py-1 rounded bg-gray-100"
                        onClick={() =>
                          handleUpdateCart(
                            item.menu_id,
                            (cart.find((i) => i.menu_id === item.menu_id)
                              ?.quantity || 0) - 1
                          )
                        }
                      >
                        -
                      </button>
                      <span>
                        {cart.find((i) => i.menu_id === item.menu_id)
                          ?.quantity || 0}
                      </span>
                      <button
                        className="px-3 py-1 rounded bg-gray-100"
                        onClick={() => handleAddToCart(item)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* Cart Total */}
          {cart.length > 0 && (
            <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg p-4">
              <div className="max-w-7xl mx-auto flex justify-between items-center">
                <span className="text-xl font-bold">
                  Total: ${calculateTotal()}
                </span>
                <button
                  onClick={handleCheckout}
                  className="bg-[#abbfff] text-white px-6 py-2 rounded hover:bg-[#4e7ab4] transition"
                >
                  Checkout
                </button>
              </div>
            </div>
          )}

          {/* Image Preview Modal */}
          {previewImage && (
            <div
              className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
              onClick={closePreview}
            >
              <img
                src={previewImage}
                alt="Preview"
                className="max-w-[90%] max-h-[90vh] object-contain"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MenuDisplay;
