import React, { useEffect } from 'react';
import BackButton from './BackButton';


const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-w-6xl mx-auto px-4 py-12 relative">
        <BackButton />
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-gray-800 text-center mb-8">Terms and Conditions</h1>

      <div className="space-y-8">
        <section className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Service Usage</h2>
          <p className="text-gray-600 leading-relaxed">
            By accessing and using DineFaster's services, you agree to comply with these terms and conditions. Our platform provides restaurant management and ordering solutions designed to enhance your dining experience.
          </p>
        </section>

        <section className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. User Responsibilities</h2>
          <div className="space-y-4 text-gray-600 leading-relaxed">
            <p>Users are responsible for:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Maintaining the confidentiality of their account credentials</li>
              <li>Providing accurate and up-to-date information</li>
              <li>Complying with all applicable laws and regulations</li>
              <li>Using the service in a manner that does not disrupt other users</li>
            </ul>
          </div>
        </section>

        <section className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Privacy Policy</h2>
          <div className="space-y-4 text-gray-600 leading-relaxed">
            <p>Our privacy policy outlines how we:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Collect and use your personal information</li>
              <li>Protect your data and maintain confidentiality</li>
              <li>Handle data retention and deletion requests</li>
              <li>Comply with data protection regulations</li>
            </ul>
          </div>
        </section>

        <section className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Payment Terms</h2>
          <div className="space-y-4 text-gray-600 leading-relaxed">
            <p>All payments processed through our platform are:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Secure and encrypted</li>
              <li>Subject to verification and approval</li>
              <li>Processed in accordance with local regulations</li>
              <li>Refundable according to our refund policy</li>
            </ul>
          </div>
        </section>

        <section className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Service Modifications</h2>
          <p className="text-gray-600 leading-relaxed">
            DineFaster reserves the right to modify, suspend, or discontinue any aspect of our services at any time. Users will be notified of significant changes that affect their use of the platform.
          </p>
        </section>
      </div>

      <div className="mt-12 text-center">
        <p className="text-sm text-gray-500">
          Last updated: {new Date().toLocaleDateString()}
        </p>
      </div>
    </div>
    </div>
  );
};

export default TermsAndConditions;
